<template>
  <div class="wrapper">
    <admin-title title="我的优势"></admin-title>
    <el-button
      type="primary"
      size="small"
      style="margin-right: 10px"
      @click="addItem()"
    >添加</el-button>
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      label-width="120px"
    >
      <el-table
        :data="formData.config_data"
        style="width: 100%"
        @row-click="handleRowClick"
      >
        <el-table-column
          prop="title"
          label="标题"
          width="450"
        >
          <template #default="{ row }">
            <el-input
              v-model="row.title"
              placeholder="请输入标题"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="icon"
          label="icon"
          width="200"
        >
          <template #default="{ row }">
            <UploadImg :url.sync="row.icon"></UploadImg>
          </template>
        </el-table-column>
        <el-table-column
          prop="dsc"
          label="描述"
          width="600"
        >
          <template #default="{ row }">
            <el-input type="textarea"
              v-model="row.dsc"
              placeholder="请输入描述"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="100"
        >
          <template #default="{$index }">
            <el-button
              type="text"
              size="small"
              @click.stop="removeItem($index)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-form-item label="">
        <el-button
          type=""
          size="small"
          @click="getSiteDetail"
        >取 消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="save"
        >保 存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getDetailAPI, settingAPI } from './api'
import UploadImg from '@/components/qiniu-uploader/uploader-img.vue'

export default {
  name: 'AdvantageConfig',
  components: { UploadImg },
  data () {
    return {
      formData: {
        key: 'advantage',
        config_data: [
          {
            dsc: '',
            icon: '',
            title: ''
          } // 初始化一个空项
        ]
      },
      rules: {
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        dsc: [{ required: true, message: '请输入描述', trigger: 'blur' }]
      }
    }
  },

  mounted () {
    this.getDetail()
  },

  methods: {
    cancel () {
      this.getDetail()
    },
    handleRowClick (row) {
      // 选中某一行时，聚焦输入框
      const input = document.querySelector(`[v-model="${row.title}"]`)
      if (input) {
        input.focus()
      }
    },
    addItem () {
      this.formData.config_data.push({
            dsc: '',
            icon: '',
            title: '', })
    },
    removeItem (index) {
      this.formData.config_data.splice(index, 1)
    },
    async getDetail () {
      const res = await getDetailAPI(this.formData.key)
      if (res) {
        this.formData = res
      }
    },
    save () {
      this.formData.config_data = this.formData.config_data.map((item, index) => {
        return {
          ...item,
          index
        }
      })
      settingAPI(this.formData).then(() => {
        this.$message.success('保存成功')
        this.getSiteDetail()
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
